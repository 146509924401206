<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="/">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>目录管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索 -->
    <div class="content">
      <el-form
        inline
        :model="query"
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
        @submit.native.prevent="getList()"
      >
        <el-form-item label="搜索">
          <el-input v-model="query.key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selClick()">查询</el-button>
          <el-button
            type="success"
            @click="
              dialogVisible = true;
              form = { isEnable: true };
              videoList = [];
            "
            >添加目录</el-button
          >
        </el-form-item>
        <!-- <div class="uploader uploader-danger" id="uploadExcel"></div> -->
      </el-form>

      <!-- 主体 -->
      <el-card class="box-card table">
        <div class="clearfix" slot="header">
          <span>{{ query.name }}</span>
        </div>

        <!-- 列表 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
          @sort-change="sortChange"
          @filter-change="filterTag"
        >
          <el-table-column prop="chapCode" label="目章节编号"></el-table-column>
          <el-table-column prop="chapName" label="章节名称"></el-table-column>
          <el-table-column prop="duration" label="时长"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="isEnable" label="是否可用">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isEnable" type="success">是</el-tag>
              <el-tag v-else type="warn">否</el-tag>
            </template>
          </el-table-column>

          <!-- 操作 -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-edit"
                    @click="editClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="warning"
                    size="small"
                    icon="el-icon-delete"
                    @click="delClick(scope.row)"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 50, 100]"
            layout="total,prev,sizes,pager,next,jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
    <!-- 弹框 -->
    <el-dialog
      :title="form.id > 0 ? '编辑目录' : '添加目录'"
      :visible.sync="dialogVisible"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm(form)"
      >
        <el-form-item
          prop="chapCode"
          label="章节编号"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-input
            v-model="form.chapCode"
            placeholder="请输入章节编号"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="chapName"
          label="章节名称"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-input
            v-model="form.chapName"
            placeholder="请输入章节名称"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="duration"
          label="时长"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-input v-model="form.duration" placeholder="请输入时长">
            <template slot="append">分钟</template>
          </el-input>
        </el-form-item>

        <el-form-item prop="summary" label="简介">
          <el-input
            v-model="form.summary"
            type="textarea"
            placeholder="请输入简介"
          ></el-input>
        </el-form-item>

        <el-form-item prop="sort" label="排序">
          <el-input
            type="number"
            v-model.number="form.sort"
            placeholder="请输入排序"
          ></el-input>
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item prop="isEnable" label="是否可用">
              <el-switch v-model="form.isEnable"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
export default {
  data() {
    return {
      domain: url.getDomain(),
      query: {},
      form: {},
      dialogVisible: false,
      submiting: false,
      loading: false,
      tableData: [],
      total: 0,
      currentPage4: 1,
      pageSize: 10,
    };
  },
  mounted() {
    this.query.japanID = this.$route.query.id;
    this.form.japanID = this.$route.query.id;
    this.query.name = this.$route.query.name;
    //   列表
    this.getList();
  },
  methods: {
    handleSizeChange: function (val) {
      this.pageSize = val;
      this.currentPage41;
      this.getList();
    },
    handleCurrentChange: function (val) {
      this.currentPage4 = val;
      this.getList();
    },
    // 排序
    sortChange: function (sortColumn) {
      this.query.sort = sortColumn.sort;
      //   this.query.order = sortColumn.order;
      this.getList();
    },
    // 筛选
    filterTag: function (filter) {
      var val = filter["isEnable"][0];
      this.getList();
    },
    // 列表
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = this.query;
      params.pageSize = _this.pageSize;
      params.pageIndex = _this.currentPage4;
      var link = url.getVideo("GetList_Cata");
      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    // 查询
    selClick: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    // 编辑
    editClick: function (row) {
      this.dialogVisible = true;
      var id = row.id;
      var link = url.getVideo("GetModel_Cata");
      $.get(link, { id: id }, (res) => {
        this.form = res;
      });
    },
    // 删除
    delClick: function (row) {
      var _this = this;
      var id = row.id;
      var link = url.getVideo("Delete_Cata");

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 提交
    submitForm: function (formName) {
      this.form.japanID = this.$route.query.id;
      var _this = this;
      var info = _this.form;
      var link = url.getVideo("Save_Cata");
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          // 提交后台  添加或修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>